import { createFeatureSelector, createSelector } from '@ngrx/store';
import { MemberActivitiesState } from './member-activities.state';

const selectMemberActivitiesState =
  createFeatureSelector<MemberActivitiesState>('member-activities');

export const selectAllMemberActivities = createSelector(
  selectMemberActivitiesState,
  (state) => state.memberActivities
);

export const selectActiveMemberActivitiesCount = createSelector(
  selectMemberActivitiesState,
  (state) => state.activeCount
);

export const selectActiveMemberActivitiesEnabled = createSelector(
  selectMemberActivitiesState,
  (state) => state.enabled
);
