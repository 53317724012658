import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpEvent,
  HttpResponse,
  HttpHandler,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { Observable, of, throwError, timer } from 'rxjs';
import {
  takeUntil,
  map,
  filter,
  mergeMap,
  shareReplay,
  switchMap,
  catchError,
} from 'rxjs/operators';
import { LogOutService } from '@services/logout.service';
import { RouteUtilities } from '@utilities/route.utilities';
import { StringifyParsedUrlOptions } from '@interfaces/stringify-parsed-url-options.model';
import { AppConfigService } from '@services/app.config.service';
import { HttpMockInterceptorService } from './http.mock.interceptor.service';
import { UUID } from 'angular2-uuid';
import { AppConfig } from '@interfaces/app-config.model';
import { ChatService } from '@services/pat-chat/chat.service';
import { cloneDeep } from 'lodash';
import { AppState } from '@state/app.state';
import { Store } from '@ngrx/store';
import { updateMetaCacheStatus } from '@store/rates-serp/serp-summary/serp-summary.actions';

@Injectable({
  providedIn: 'root',
})
export class HttpAppInterceptorService {
  private cache: Map<string, Observable<HttpEvent<any>>> = new Map();
  private pollingInterval: number = 1000;
  private cacheKeyUrlOptions: StringifyParsedUrlOptions = {
    fullyQualified: false,
    excludeQueryParams: [{ key: 'transaction_id' }],
  };
  private queryParamsToClean = ['cache', 'e2e_spec_mock_config'];

  constructor(
    private logoutService: LogOutService,
    private routeUtilities: RouteUtilities,
    private appConfigService: AppConfigService,
    private mockService: HttpMockInterceptorService,
    private chatService: ChatService,
    private readonly store: Store<AppState>
  ) {}

  /**
   * handleInterception: Apply interception logic.
   * @param request: HttpRequest<any>
   * @param next: HttpHandler
   * @returns Observable<HttpEvent<any>>
   */
  public handleInterception(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return this.appConfigService.config.pipe(
      switchMap((appConfig) => {
        // Determine cachability
        const isCachable = this.isCachable(request);

        // Get cleaned and normalized request URL
        const url = this.getRequestUrl(request);

        // Clean request query params
        request = this.cleanQueryParams(request);

        // Get cache and return if it exists
        if (isCachable) {
          const cache = this.getCache(url);
          if (cache) {
            return cache;
          }
        }

        // Get next handle
        let nextHandle = this.getNextHandle(request, next, appConfig);

        if (isCachable) {
          // Cache request
          nextHandle = this.putCache(url, nextHandle);
        }

        return nextHandle;
      })
    );
  }

  /**
   * isCachable: Determine if request is cachable.
   * Cachable if request method is GET and cache param not set to 'false'.
   * @param request: HttpRequest<any>
   * @returns boolean
   */
  private isCachable(request: HttpRequest<any>): boolean {
    return request.method === 'GET' && request.params.get('cache') !== 'false';
  }

  /**
   * getNextHandle: Get the request's next handle.
   * Applies in-flight cancelling, polling, and E2E mock handlers.
   * @param request: HttpRequest<any>
   * @param next: HttpHandler
   * @param appConfig: AppConfig
   * @returns Observable<HttpEvent<any>>
   */
  private getNextHandle(
    request: HttpRequest<any>,
    next: HttpHandler,
    appConfig: AppConfig
  ): Observable<HttpEvent<any>> {
    let nextHandle: Observable<HttpEvent<any>>;

    if (appConfig.e2e) {
      // Get mock recording if in E2E mode
      nextHandle = this.mockService.getMockRecording(request, next, appConfig);
    }

    if (!nextHandle) {
      // Add api key to request
      request = this.addApiKey(request, appConfig);

      // Add uuid to request as nonce
      request = this.addUUID(request, appConfig);

      // Make request
      nextHandle = next.handle(request);

      // Apply cancel handler
      nextHandle = this.applyCancelHandler(nextHandle);

      // Apply polling handler
      nextHandle = this.applyPollingHandler(
        nextHandle,
        request,
        next,
        appConfig
      );

      // Apply share replay to prevent cached requests from making new http calls
      nextHandle = this.applyReplay(nextHandle);
    }

    return nextHandle;
  }

  private addApiKey(
    request: HttpRequest<any>,
    appConfig: AppConfig
  ): HttpRequest<any> {
    const addKey =
      !request.url.startsWith('http') &&
      request.url.includes('/api/') &&
      appConfig.client_configs.api_key?.ui2;
    return addKey
      ? request.clone({
          headers: request.headers.set(
            'x-api-key',
            appConfig.client_configs.api_key.ui2
          ),
        })
      : request;
  }

  private addUUID(
    request: HttpRequest<any>,
    appConfig: AppConfig
  ): HttpRequest<any> {
    const apiKey =
      !request.url.startsWith('http') &&
      request.url.includes('/api/') &&
      appConfig.client_configs.api_key?.ui2;
    const uuid = UUID.UUID();
    return apiKey
      ? request.clone({ headers: request.headers.set('x-nonce', uuid) })
      : request;
  }

  /**
   * applyCancelHandler: Apply cancel handler to request event observable.
   * Cancel open requests when app starts to log out or when a chat is closed.
   * @param event: Observable<HttpEvent<any>>
   * @returns Observable<HttpEvent<any>>
   */
  private applyCancelHandler(
    event: Observable<HttpEvent<any>>
  ): Observable<HttpEvent<any>> {
    if (this.chatService.isConnected.getValue()) {
      return event.pipe(takeUntil(this.chatService.stopPolling));
    }
    return event.pipe(takeUntil(this.logoutService.onCancelRequests()));
  }

  private applyPollingHandler(
    event: Observable<HttpEvent<any>>,
    request: HttpRequest<any>,
    next: HttpHandler,
    appConfig: AppConfig
  ): Observable<HttpEvent<any>> {
    return event.pipe(
      // Only poll responses
      filter((httpEvent) => httpEvent instanceof HttpResponse),
      // Map to an incomplete or processing error under certain conditions
      mergeMap((httpEvent: HttpEvent<any>) =>
        this.handlePollingResponse(httpEvent)
      ),
      // Catches errors and retries requests with new UUID
      catchError((error: HttpErrorResponse) =>
        this.handlePollingError(error, request, next, appConfig)
      )
    );
  }

  /**
   * handlePollingError: Switches to request with updated UUID header after a delay.
   * Retry the HTTP request only if the error has a message of "incomplete" or "processing".
   */
  private handlePollingError(
    error: HttpErrorResponse,
    request: HttpRequest<any>,
    next: HttpHandler,
    appConfig: AppConfig
  ): Observable<any> {
    if (error.message === 'incomplete' || error.message === 'processing') {
      // Add uuid to new request as nonce
      const retryRequest = this.addUUID(request, appConfig);
      // Delay the retry request by 1 second and switch to the new observable
      return timer(this.pollingInterval).pipe(
        switchMap(() => this.getNextHandle(retryRequest, next, appConfig))
      );
    }
    return throwError(() => error);
  }

  /**
   * putCache: Create new cache item.
   * @param url: string
   * @param httpEvent: Observable<HttpEvent<any>>
   * @returns Observable<HttpEvent<any>>
   */
  private putCache(
    url: string,
    httpEvent: Observable<HttpEvent<any>>
  ): Observable<HttpEvent<any>> {
    this.cache.set(url, this.applyCacheFilter(httpEvent));
    return this.getCache(url);
  }

  /**
   * getCache: Gets the request from the cache.
   * @param url: string
   * @returns Observable<HttpEvent<any>>
   */
  private getCache(url: string): Observable<HttpEvent<any>> {
    return this.cache.get(url);
  }

  /**
   * cleanQueryParams: Removes query params from the request.
   * @param request: HttpRequest<any>
   * @returns HttpRequest<any>
   */
  private cleanQueryParams(request: HttpRequest<any>): HttpRequest<any> {
    let params = request.params;
    this.queryParamsToClean.forEach((param) => (params = params.delete(param)));
    params = this.cleanLocale(params);
    return request.clone({
      params: params,
    });
  }

  /**
   * getRequestUrl: Get cleaned and normalized request URL.
   * @param request: HttpRequest<any>
   * @returns HttpRequest<any>
   */
  private getRequestUrl(request: HttpRequest<any>): string {
    const url = request.urlWithParams;
    const parsedUrl = this.routeUtilities.parseUrl(url);
    return this.routeUtilities.stringifyParsedUrl(
      parsedUrl,
      this.cacheKeyUrlOptions
    );
  }

  private handlePollingResponse(
    event: HttpEvent<any>
  ): Observable<HttpEvent<any>> {
    if (this.cacheStatusIncomplete(event)) {
      this.updateRatesSummaryCacheStatus(event);
      // Error picked up by retryWhen()
      throw new Error('incomplete');
    } else if (this.statusProcessing(event)) {
      throw new Error('processing');
    }
    return of(event);
  }

  private cacheStatusIncomplete(event: HttpEvent<any>): boolean {
    return !!(
      event &&
      event instanceof HttpResponse &&
      event.body &&
      event.body._meta &&
      event.body._meta.cache_status &&
      event.body._meta.cache_status !== 'complete'
    );
  }

  private statusProcessing(event: HttpEvent<any>): boolean {
    return !!(
      event &&
      event instanceof HttpResponse &&
      event.body &&
      event.body.status === 'processing'
    );
  }

  private updateRatesSummaryCacheStatus(event: HttpEvent<any>): void {
    if (this.isRatesSummaryCall(event)) {
      this.store.dispatch(
        updateMetaCacheStatus({ status: event['body']?._meta?.cache_status })
      );
    }
  }

  private isRatesSummaryCall(event: HttpEvent<any>): boolean {
    const summaryURL = 'api/providers/summary.json';
    const ratesParam = 'billing_code';
    const url = event['url'];
    return url.includes(summaryURL) && url.includes(ratesParam);
  }

  private applyReplay(
    httpEvent: Observable<HttpEvent<any>>
  ): Observable<HttpEvent<any>> {
    return httpEvent.pipe(shareReplay(1));
  }

  private applyCacheFilter(
    httpEvent: Observable<HttpEvent<any>>
  ): Observable<HttpEvent<any>> {
    return httpEvent.pipe(
      // Only cache response events
      filter((event) => event instanceof HttpResponse),
      // Apply header to indicate response is from cache
      map((response: HttpResponse<any>) =>
        response.clone({
          headers: response.headers.set('X-From-Cache', 'true'),
          body: cloneDeep(response.body),
        })
      )
    );
  }

  private cleanLocale(params: HttpParams) {
    const locale = params.get('locale');
    if (
      params.has('locale') &&
      (locale === null || locale === 'null' || locale === 'nu')
    ) {
      params = params.set('locale', 'en');
    }
    return params;
  }
}
