<div class="mobile-header-overlay">
  <div
    fxLayout="row"
    fxLayoutAlign="start center"
    class="mobile-header-title-container"
    data-cy="mobile-header-title-container"
    fxLayoutGap.lt-md="16px"
  >
    <div fxFlex="7" fxLayoutAlign="start center">
      <button
        (click)="dialogRef.close()"
        class="close-button p-0 h-4 ml-4"
        type="button"
        [attr.aria-label]="'app_global_close_mobile_header' | translate"
      >
        <mat-icon
          svgIcon="dls-times-plain-light"
          data-cy="icon-close.close"
          class="close"
        >
        </mat-icon>
      </button>
      <p class="mobile-header-title">{{ 'app_global_menu' | translate }}</p>
    </div>
  </div>
  <div>
    <div *ngIf="!showLanguage">
      <div
        *ngIf="auth.auth_status && !suppressMembers"
        class="mobile-header-option"
        tabindex="0"
        (keydown.enter)="goto('account')"
        (click)="goto('account')"
      >
        {{ 'global_header_profile' | translate }}
      </div>
      <div *ngIf="nbaEnabled | async" class="mobile-header-option" tabindex="0">
        {{ 'app_global_activities' | translate }}
      </div>
      <div
        *ngIf="!suppressLanguage"
        class="mobile-header-option"
        data-cy="mobile-header-option"
        (keydown.enter)="showLanguage = !showLanguage"
        (click)="showLanguage = !showLanguage"
        tabindex="0"
        role="link"
      >
        {{ 'app_global_language' | translate }}
      </div>
      <div
        *ngIf="ssEnabled | async"
        class="mobile-header-option"
        tabindex="0"
        (keydown.enter)="goto('sspromo')"
        (click)="goto('sspromo')"
      >
        {{ 'global_header_about_ss' | translate }}
      </div>
      <div
        *ngIf="showLoginLink()"
        class="mobile-header-option"
        data-cy="mobile-header-overlay.login-option"
        (keydown.enter)="dialogRef.close()"
        (click)="dialogRef.close()"
      >
        <platform-login-link
          analytics
          [url]="auth.url"
          [text]="'app_global_login' | translate"
        >
        </platform-login-link>
      </div>
      <div
        *ngIf="auth.auth_status && !logoutDisabled"
        class="mobile-header-option"
        (keydown.enter)="mobileLogout()"
        (click)="mobileLogout()"
        tabindex="0"
        role="link"
      >
        {{ 'app_global_logout' | translate }}
      </div>
    </div>
    <div
      *ngIf="showLanguage"
      (keydown.enter)="dialogRef.close()"
      (click)="dialogRef.close()"
    >
      <app-mobile-language [isMobile]="true"></app-mobile-language>
    </div>
  </div>
</div>
