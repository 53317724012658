import { Injectable } from '@angular/core';
import { SearchFilterV2 } from '@interfaces/search-filter-v2.model';
import { SearchParamType } from '@interfaces/search-param-type.interface';
import { combineLatest, forkJoin, Observable, of, Subscription } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  filter as rxjsFilter,
  map,
  switchMap,
  take,
  withLatestFrom,
} from 'rxjs/operators';
import {
  cloneDeep,
  each,
  filter,
  find,
  flatMap,
  groupBy,
  identity,
  includes,
  orderBy,
  pickBy,
  reject,
  sortBy,
  sumBy,
} from 'lodash';
import searchTypeMap from '@utilities/search-type-map.utilities';
import { CriticalFilters } from '@interfaces/critical-filters.model';
import { SearchParamsService } from '@components/+search/search-params.service';
import { NetworksService } from '@services/networks.service';
import { SearchFiltersFacets } from '@interfaces/search-filters-facets.model';
import { CustomFilterOptions } from '@components/+search/classes/custom-filter-options.class';
import { HttpClient, HttpParams } from '@angular/common/http';
import { SearchFacetParamsService } from '@components/+search/classes/search-facet-params.service';
import { TranslateService } from '@ngx-translate/core';
import { select, Store } from '@ngrx/store';
import { AppState } from '@state/app.state';
import {
  getAppliedFilters,
  getBaseFilters,
  getConfigFilters,
  getSelectedFilters,
} from '@store/search-filters-v2/search-filters-v2.selectors';
import {
  SearchFilterV2Dls,
  SearchFilterV2OptionDls,
} from '@interfaces/search-filter-v2-dls.model';
import {
  selectSearchTypeSortDefaultOption,
  selectSearchTypeSortOptions,
} from '@store/search-filters/sort-filters/sort-filters.selectors';
import { SelectedFilter } from '@interfaces/selected-filter.interface';
import {
  selectRadiusFilters,
  selectSearchTypeDefaultRadius,
} from '@store/search-filters/radius-filters/radius-filters.selectors';
import { SearchSortOption } from '@interfaces/search-sort-option.model';
import { FacetGeo } from '@interfaces/facet-geo.model';
import { ProductAnalyticsService } from '@services/product-analytics/product-analytics.service';
import { ConfigurationService } from '@services/configuration.service';
import {
  isProviderTypeSubSearch,
  getSearchType,
} from '@store/search/search.selectors';
import { SearchFilterV2EffectData } from '@interfaces/search-filter-v2-effect-data.interface';
import { SerpService } from '@services/serp/serp.service';
import { ListData, ListItemData } from '@zelis/dls/list-item';
import { RouteUtilities } from '@utilities/route.utilities';
import { SettingsService } from '@services/settings.service';
import {
  AwardGroup,
  AwardSupplemental,
} from '@interfaces/award-supplemental-setting.interface';

@Injectable()
export class SearchFiltersV2Service {
  private customFilterOptions = new CustomFilterOptions();
  private validAnpValues = ['Y', 'O', 'OX', 'XO', 'EO', 'OE', 'F'];
  private routeUtils = new RouteUtilities();
  private awardSupplemental: AwardSupplemental;

  constructor(
    private searchParamsService: SearchParamsService,
    private networksService: NetworksService,
    private searchFacetParamsService: SearchFacetParamsService,
    private http: HttpClient,
    private translateService: TranslateService,
    private store: Store<AppState>,
    private pendoService: ProductAnalyticsService,
    private configurationService: ConfigurationService,
    private serpService: SerpService,
    private settingsService: SettingsService
  ) {
    this.getAwardSupplemental();
  }

  public getPreSelectedFiltersForSearchType(
    searchType: SearchParamType,
    includeUnselected?: boolean,
    defaultsOnly?: boolean
  ): Observable<SelectedFilter> {
    return this.configurationService.signatureResolved().pipe(
      switchMap(() => {
        const mappedSearchType: SearchParamType =
          searchTypeMap[searchType] || searchType;
        return this.store.pipe(
          select(getConfigFilters),
          rxjsFilter((configFilters) => !!configFilters),
          withLatestFrom(
            this.store
              .select(selectSearchTypeSortDefaultOption(mappedSearchType))
              .pipe(rxjsFilter((sortOptions) => !!sortOptions)),
            this.store
              .select(selectSearchTypeDefaultRadius(mappedSearchType))
              .pipe(
                rxjsFilter(
                  (defaultRadius) =>
                    !!defaultRadius || searchType === 'serp_lite'
                )
              ),
            this.store.select(isProviderTypeSubSearch)
          ),
          take(1),
          map(
            ([
              configFilters,
              defaultSort,
              defaultRadius,
              _isProviderTypeSubSearch,
            ]) => {
              const selectedFilters = {
                radius: defaultRadius,
                sort: defaultSort.query,
              } as SelectedFilter;

              if (_isProviderTypeSubSearch) {
                // on ProviderTypeSubSearch we want to include filter as a default
                selectedFilters['provider_type_description'] =
                  _isProviderTypeSubSearch;
              }

              if (!['rates', 'serp_lite'].includes(mappedSearchType)) {
                selectedFilters['sort_translation'] = defaultSort.translation;
              }

              if (defaultsOnly && !includeUnselected) {
                return selectedFilters;
              }

              configFilters.forEach((_filter) => {
                const ignoreProviderTypeSubSearchFacet =
                  _isProviderTypeSubSearch &&
                  _filter.facet === 'provider_type_description';
                if (!ignoreProviderTypeSubSearchFacet) {
                  selectedFilters[_filter.facet] = defaultsOnly
                    ? undefined
                    : _filter.default?.[mappedSearchType];
                }
              });
              return includeUnselected
                ? selectedFilters
                : pickBy(selectedFilters, identity);
            }
          )
        );
      })
    );
  }

  public getDefaultFiltersForSearchType(
    searchType: SearchParamType,
    includeUnselected?: boolean
  ): Observable<SelectedFilter> {
    return this.getPreSelectedFiltersForSearchType(
      searchType,
      includeUnselected,
      true
    );
  }

  public getMappedFilters(): Observable<SearchFilterV2Dls[]> {
    return this.getBaseFilters().pipe(
      switchMap((baseFilters) =>
        this.getMappedAppliedFilters().pipe(
          map((appliedFilters) => [baseFilters, appliedFilters])
        )
      ),
      withLatestFrom(this.store.select(getSelectedFilters)),
      rxjsFilter(
        ([[baseFilters, appliedFilters], selectedFilters]) =>
          !!baseFilters && !!appliedFilters && !!selectedFilters
      ),
      map(([[baseFilters, appliedFilters], selectedFilters]) => [
        // MAP BASE FILTERS HERE TO KEEP SELECTED FILTERS UPDATED
        this.mapFiltersToDLSFilters(baseFilters, selectedFilters),
        appliedFilters,
      ]),
      map(
        ([baseFilters, appliedFilters]: [
          SearchFilterV2Dls[],
          SearchFilterV2Dls[]
        ]) => {
          // HIDE ANY BASE FILTERS WITH NO OPTIONS AND IF ONLY OPTIONS ARE FALSE
          const baseFiltersWithOptions = baseFilters.filter((_filter) => {
            return (
              _filter.options?.length &&
              !_filter.options?.every((opt) => !opt.value)
            );
          });
          // BASE FILTERS OPTIONS THAT ARE NOT AVAILABLE WITHIN APPLIED FILTERS SHOULD BE DISABLED.
          return this.mapBaseFiltersOptionsDisabled(
            baseFiltersWithOptions,
            appliedFilters
          );
        }
      ),
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
    );
  }

  public getSerpLiteMappedFilters(): Observable<SearchFilterV2Dls[]> {
    return this.getMappedAppliedFilters().pipe(
      withLatestFrom(this.store.select(getSelectedFilters)),
      rxjsFilter(
        ([appliedFilters, selectedFilters]) =>
          !!appliedFilters && !!selectedFilters
      ),
      map(([appliedFilters, selectedFilters]) => {
        return this.buildSerpLiteFilters(
          this.mapFiltersToDLSFilters(appliedFilters, selectedFilters)
        );
      })
    );
  }

  /**
   *  @description Takes the selected filter output from SearchFiltersChipsComponent and
   *  maps it into the expected format needed to update the URL params. The ANP filter is
   *  a special case - the summary request expects the selected value to equal 'F,Y,EO,OE,OX,O,XO'.
   *  Example of mappings when selecting 'Male' from the 'Gender' filter:
   *    Before: [{facet: 'professional_gender', options: [{name: 'Male', value: 'M', selected: true}, {name: 'Female', value: 'F'}]}]
   *    After: { professional_gender: 'M' }
   *  @param selectedFilters: SearchFilterV2Dls[]
   *  @returns SelectedFilter: SelectedFilter
   */
  public mapSelectedFilterOutput(
    selectedFilters: SearchFilterV2Dls[],
    returnNames = false,
    includeSortTranslation = false
  ): SelectedFilter {
    selectedFilters = this.mergeLikeFilters(selectedFilters);
    const mappedFilters = {};

    selectedFilters.forEach((searchFilter: SearchFilterV2Dls) => {
      const selectedFiltersValue = searchFilter.options
        .flatMap((option) => {
          if (
            includeSortTranslation &&
            searchFilter.facet === 'sort' &&
            option.selected
          ) {
            mappedFilters['sort_translation'] = option['translation'];
          }
          return option.selected
            ? [returnNames ? option.name : option.value]
            : [];
        })
        .join('|');
      mappedFilters[searchFilter.facet] = selectedFiltersValue || null;

      if (
        searchFilter.facet === 'contract_accepting_new_patients' &&
        selectedFiltersValue === 'true'
      ) {
        mappedFilters[searchFilter.facet] = 'F,Y,EO,OE,OX,O,XO';
      }

      if (searchFilter.facet === 'virtual_only' && selectedFiltersValue) {
        mappedFilters[searchFilter.facet] = 'N';
      }
    });
    return mappedFilters;
  }

  public getFiltersWithFacetData(
    filtersFromStore: SearchFilterV2[],
    combinedParams: any,
    searchParamType: SearchParamType,
    sortOptions: SearchSortOption[],
    radiusConfig: SearchFilterV2,
    defaultFilters: SelectedFilter,
    baseFacetData: boolean = false
  ): Observable<SearchFilterV2[]> {
    const mappedSearchType: SearchParamType =
      searchTypeMap[searchParamType] || searchParamType;
    return this.checkConfigToHideOrDisable(
      mappedSearchType,
      combinedParams,
      filtersFromStore
    ).pipe(
      switchMap((filteredByType: SearchFilterV2[]) => {
        return combineLatest([
          this.getCombinedFacetRequests(
            combinedParams,
            mappedSearchType,
            filteredByType,
            filtersFromStore,
            radiusConfig,
            baseFacetData
          ),
          this.networksService.resolvedNetwork,
        ]).pipe(
          withLatestFrom(this.store.select(isProviderTypeSubSearch)),
          switchMap(
            ([
              [[locationGeoFacet, facets], network],
              _isProviderTypeSubSearch,
            ]) => {
              const filterWithFacetData = this.matchFacetOptionsForStoreData(
                facets,
                filteredByType,
                network.tier_code,
                _isProviderTypeSubSearch
              );

              const sortFacet = this.buildSortFilter(
                sortOptions,
                defaultFilters
              );

              // SORT FILTER NEEDS TO BE IN LAST POSITION FOR CHIPS FILTER
              filterWithFacetData.push(sortFacet);

              if (mappedSearchType === 'serp_lite') {
                return of(filterWithFacetData);
              }

              const radiusFilter = this.buildRadiusFilter(
                locationGeoFacet,
                radiusConfig,
                defaultFilters,
                mappedSearchType
              );
              // RADIUS FILTER NEEDS TO BE IN FIRST POSITION FOR CHIPS FILTER
              filterWithFacetData.unshift(radiusFilter);
              return of(filterWithFacetData);
            }
          )
        );
      })
    );
  }

  // Get filtered search filters by current search type
  // Some filters need to be hidden on a particular search type, i.e. hide has_incentive on search_specialty searches
  public checkConfigToHideOrDisable(
    searchParamType: SearchParamType,
    selectedFilters: any,
    filtersFromStore: SearchFilterV2[]
  ): Observable<SearchFilterV2[]> {
    return of(
      filter(filtersFromStore, (configItem: SearchFilterV2) => {
        const mappedType = searchTypeMap[searchParamType] || searchParamType;
        this.disableFilters(mappedType, configItem, selectedFilters);
        const routeMatch = configItem.facet === searchParamType;
        return !(routeMatch || includes(configItem.hide, mappedType));
      })
    );
  }

  public sendPendoNoResultsFilteredData(searchTerm: string): void {
    this.store
      .select(getSelectedFilters)
      .pipe(
        rxjsFilter((filters) => !!filters),
        withLatestFrom(
          this.store
            .select(getBaseFilters)
            .pipe(rxjsFilter((filters) => !!filters))
        ),
        take(1)
      )
      .subscribe(([selectedFilters, baseFilters]) => {
        const trackEventName = 'No Results Filter Selections 24.3';
        const _selectedFilters: string =
          this.buildPendoTranslatedSelectedFiltersValue(
            selectedFilters,
            baseFilters
          );
        const data = {
          filter_no_results: true,
          filter_no_results_selections: _selectedFilters,
          search_term: searchTerm,
        };
        this.pendoService.sendTrackEvent(trackEventName, data);
      });
  }

  public sendPendoToggleFilter(
    selectedFilter: ListData,
    searchTerm: string
  ): void {
    const optionValue = selectedFilter.options.find((option: ListItemData) => {
      return ['Y', 'y', 'true', true].includes(option.value);
    });

    const trackData = {
      filter_or_sort_opened: true,
      filter_default_value: selectedFilter.default,
      filter_selection_path_one: `${selectedFilter.name} > ${optionValue.selected}`,
      search_term: searchTerm,
    };

    this.pendoService.sendTrackEvent('Filter Selections 24.3', trackData);
  }

  public sendPendoMenuFilter(
    selectedFilter: SearchFilterV2Dls[] | string,
    searchTerm: string,
    isAllFilterMenu = false,
    clearAll = false
  ): void {
    const trackData = {
      all_filters_opened: isAllFilterMenu,
      filter_or_sort_opened: !clearAll,
      filter_default_value: null,
      filter_selection_path_one: null,
      search_term: searchTerm,
    };

    if (selectedFilter) {
      if (typeof selectedFilter !== 'string') {
        const mappedFilters = this.mapSelectedFilterOutput(
          selectedFilter,
          true
        );
        (trackData.filter_default_value = selectedFilter[0].default),
          (trackData.filter_selection_path_one = `${selectedFilter[0].name} > ${
            mappedFilters[Object.keys(mappedFilters)[0]]
          }`);
      } else {
        trackData.filter_selection_path_one = selectedFilter;
      }
    }

    this.pendoService.sendTrackEvent('Filter Selections 24.3', trackData);
  }

  public getFacetEffectData(): Observable<SearchFilterV2EffectData> {
    return of(null).pipe(
      withLatestFrom(
        this.store.select(getSearchType),
        this.store
          .select(getConfigFilters)
          .pipe(rxjsFilter((configFilters) => !!configFilters)),
        this.store
          .select(selectRadiusFilters)
          .pipe(rxjsFilter((state) => !state['loading']))
      ),
      switchMap(([_x, searchParamType, configFilters, radiusConfig]) => {
        const mappedSearchType: SearchParamType =
          searchTypeMap[searchParamType] || searchParamType;
        if (mappedSearchType === 'serp_lite') {
          configFilters = configFilters.filter((configFilter) => {
            return (
              configFilter.facet === 'field_specialty_ids' ||
              configFilter.facet === 'expertise_codes'
            );
          });
        }
        return this.getDefaultFiltersForSearchType(mappedSearchType, true).pipe(
          withLatestFrom(
            this.store
              .select(selectSearchTypeSortOptions(mappedSearchType))
              .pipe(rxjsFilter((sortOptions) => !!sortOptions))
          ),
          map(
            ([defaultFilters, sortConfig]: [
              SelectedFilter,
              SearchSortOption[]
            ]) => {
              return {
                mappedSearchType: mappedSearchType,
                configFilters: configFilters,
                defaultFilters: defaultFilters,
                sortConfig: sortConfig,
                radiusConfig: radiusConfig,
              };
            }
          )
        );
      })
    );
  }

  private buildPendoTranslatedSelectedFiltersValue(
    selectedFilters: SelectedFilter,
    baseFilters: SearchFilterV2[]
  ): string {
    const _selectedFilters: string[] = [];
    Object.keys(selectedFilters).forEach((_filter) => {
      const facet = baseFilters.find((bFilter) => bFilter.facet === _filter);
      if (facet) {
        // Multi select values use | as separator
        const selectedValues: string[] = selectedFilters[_filter].split('|');
        const translatedValues: string[] =
          facet.type === 'toggle'
            ? ['true']
            : selectedValues.map((selectedValue) => {
                return this.translateService.instant(
                  facet.options.find((option) => option.value === selectedValue)
                    ?.name || selectedValue
                );
              });
        const filterName = this.translateService.instant(facet.name);
        const filterValue = translatedValues.join('|');
        _selectedFilters.push(`${filterName}>${filterValue}`);
      }
    });
    return _selectedFilters.join(', ');
  }

  // Group facets call expect for location_geo.
  // API has better performance when requested individually.
  private getCombinedFacetRequests(
    selectedFilters: CriticalFilters,
    searchParamType: SearchParamType,
    filteredByType: any,
    filtersFromStore: SearchFilterV2[],
    radiusConfig: SearchFilterV2,
    baseFacetData: boolean
  ): Observable<SearchFiltersFacets[]> {
    const params = this.searchParamsService.setHttpParams(
      this.decodeFilter(cloneDeep(selectedFilters)),
      null,
      true,
      filtersFromStore
    );
    return forkJoin(
      this.getFacetRequests(
        params,
        searchParamType,
        filteredByType,
        radiusConfig,
        baseFacetData
      )
    );
  }

  private getFacetRequests(
    params: HttpParams,
    searchParamType: SearchParamType,
    searchFilters: SearchFilterV2[],
    radiusConfig: SearchFilterV2,
    baseFacetData: boolean
  ): Observable<SearchFiltersFacets>[] {
    const updatedParams =
      this.searchFacetParamsService.updateMultiSelectFilterParams(
        params,
        searchFilters
      );
    let facetRequestParams =
      this.searchFacetParamsService.getFacetRequestParams(
        cloneDeep(updatedParams),
        searchFilters
      );
    facetRequestParams = this.augmentForRatesSearch(
      searchParamType,
      facetRequestParams,
      baseFacetData
    );
    let locationGeoRequestParams =
      this.searchFacetParamsService.getFacetRequestParams(
        cloneDeep(updatedParams),
        [this.locationGeoFilter(radiusConfig)]
      );
    locationGeoRequestParams = this.augmentForRatesSearch(
      searchParamType,
      locationGeoRequestParams,
      baseFacetData
    );
    const locationGeoRequest = this.locationGeoFacetRequest(
      locationGeoRequestParams,
      searchParamType
    );

    return [locationGeoRequest, this.requestFacet(facetRequestParams)];
  }

  private augmentForRatesSearch(
    searchParamType: SearchParamType,
    facetRequestParams: HttpParams,
    baseFacetData: boolean
  ): HttpParams {
    if (searchParamType === 'rates' && !baseFacetData) {
      let serpSummaryParams = this.serpService.httpParams;
      facetRequestParams.keys().forEach((key: string) => {
        if (key.includes('facet')) {
          serpSummaryParams = serpSummaryParams.set(
            key,
            facetRequestParams.get(key)
          );
        }
      });
      facetRequestParams = serpSummaryParams;
    }
    return facetRequestParams;
  }

  private locationGeoFacetRequest(
    locationGeoRequestParams: HttpParams,
    searchParamType: SearchParamType
  ): Observable<SearchFiltersFacets> {
    // LOCATION_GEO NOT NEEDED ON SERP LITE
    return searchParamType === 'serp_lite'
      ? of({} as SearchFiltersFacets)
      : this.requestFacet(locationGeoRequestParams);
  }

  private locationGeoFilter(radiusConfig: SearchFilterV2): SearchFilterV2 {
    const { options } = radiusConfig;
    const facetValues = options.map((option) => option.value);
    return new SearchFilterV2({
      ...radiusConfig,
      facet_queries: [
        {
          facetQuery: 'location_geo[value]',
          value: facetValues.join(','),
        },
      ],
    });
  }

  private requestFacet(params: HttpParams): Observable<SearchFiltersFacets> {
    params = this.searchFacetParamsService.updateTiersFacetableParam(params);
    const url = `/api/providers/facets.json`;
    return this.http.get(url, { params: params, withCredentials: true }).pipe(
      catchError(() => of(null)),
      map((data: any) => (data && data.facets) || null),
      map((data: any) => this.mapFacets(data))
    );
  }

  private mapFacets(facets: any): any {
    return this.searchFacetParamsService.updateTiersFacetableProperty(facets);
  }

  private disableFilters(
    searchParamType: SearchParamType,
    configItem: SearchFilterV2,
    selectedFilters: any
  ): void {
    configItem['disabled'] = false;
    const disableAffiliation = this.shouldDisableAffiliationSearchTypeFacet(
      configItem,
      selectedFilters
    );
    if (disableAffiliation || includes(configItem.disable, searchParamType)) {
      if (disableAffiliation || selectedFilters[searchParamType]) {
        configItem['disabled'] = true;
        configItem['options'] = [];
      }
    }
  }

  private shouldDisableAffiliationSearchTypeFacet(
    configItem: SearchFilterV2,
    selectedFilters: SelectedFilter
  ): boolean {
    const affiliationTypeFromSelectedFilters =
      this.routeUtils.getSearchParamType(selectedFilters);
    const affiliationTypes = [
      'group_affiliation_ids',
      'hospital_affiliation_ids',
    ];
    return (
      configItem.facet === affiliationTypeFromSelectedFilters &&
      affiliationTypes.includes(affiliationTypeFromSelectedFilters)
    );
  }

  private matchFacetOptionsForStoreData(
    facets: SearchFiltersFacets,
    filteredByType: SearchFilterV2[],
    tierCode: string,
    _isProviderTypeSubSearch: string
  ): SearchFilterV2[] {
    facets = new SearchFiltersFacets(facets, tierCode);
    if (!facets) {
      return filteredByType;
    }
    return cloneDeep(filteredByType).map((_filter) => {
      //
      _filter.default = this.setFilterDefault(
        _isProviderTypeSubSearch,
        _filter.facet
      );
      // currently
      this.mapFilterOptions(_filter, cloneDeep(facets)[_filter.facet]);
      return _filter;
    });
  }

  private setFilterDefault(
    _isProviderTypeSubSearch: string,
    facet: string
  ): string[] | null {
    // besides radius and sort, only isProviderTypeSubSearch currently has a default
    const isProviderTypeSubSearchFacet =
      _isProviderTypeSubSearch && facet === 'provider_type_description';
    return isProviderTypeSubSearchFacet ? [_isProviderTypeSubSearch] : null;
  }

  private mapFilterOptions(
    _filter: SearchFilterV2,
    filterOptions: SearchFilterV2OptionDls[]
  ): void {
    if (!filterOptions) {
      _filter.options = [];
      return;
    }

    _filter.options = filterOptions;

    const tiersFacet = _filter.facet.split(':');
    switch (true) {
      case !!_filter.option_mapping:
        _filter.options = this.mapFacetOptionName(_filter);
        break;
      case !!_filter.option_sort:
        _filter.options = this.customFilterOptions[
          _filter.option_sort + 'OptionSort'
        ](_filter.options);
        break;
      case tiersFacet[0].indexOf('tiers') > -1:
        _filter.options.forEach((option) => {
          if (option.value !== null) {
            option.name =
              'tier_' + tiersFacet[1].toUpperCase() + '_' + option.value;
          }
        });
        break;
      default:
        _filter.options = orderBy(
          _filter.options,
          [(option) => option.name?.toString().toLowerCase()],
          ['asc']
        );
    }
    // Needs to take place after option_mapping
    _filter.options = this.augmentOverAllRatingOptionValue(
      _filter.options,
      _filter.facet
    );
    _filter.options = this.filterTogglesAndOptions(_filter);
  }

  private augmentOverAllRatingOptionValue(
    options: SearchFilterV2OptionDls[],
    facet: string
  ): SearchFilterV2OptionDls[] {
    if (facet === 'aggregate_overall_rating[$gte]') {
      return options.map((option) => ({
        ...option,
        value: option.value.replace(/<|>/g, ''),
      }));
    }
    return options;
  }

  private mapFacetOptionName(
    filter2: SearchFilterV2
  ): SearchFilterV2OptionDls[] {
    const foundOptions = [];

    each(filter2.option_mapping, (name, value) => {
      const foundOption = find(filter2.options, { value: value });

      if (foundOption) {
        foundOption.name = name;
        foundOptions.push(foundOption);
      }
    });

    return foundOptions;
  }

  private decodeFilter(
    filters: CriticalFilters,
    filterString = 'provider_type_description'
  ): CriticalFilters {
    if (filters[filterString]) {
      filters[filterString] = decodeURIComponent(filters[filterString]);
    }
    return filters;
  }

  private getBaseFilters(): Observable<SearchFilterV2[]> {
    return this.store.pipe(
      select(getBaseFilters),
      rxjsFilter((filters) => !!filters)
    );
  }

  private getMappedAppliedFilters(): Observable<SearchFilterV2Dls[]> {
    return this.store.pipe(
      select(getAppliedFilters),
      rxjsFilter((filters) => !!filters),
      map((filters) => {
        // SELECTED FILTERS ARE TRACKED ON BASE FILTERS
        return this.mapFiltersToDLSFilters(filters);
      })
    );
  }

  private markFilterOptionDisabled(
    baseOption: SearchFilterV2OptionDls,
    appliedFilterOptions: SearchFilterV2OptionDls[]
  ): SearchFilterV2OptionDls {
    const optionUnAvailable =
      appliedFilterOptions.findIndex(
        (option) => option.name === baseOption.name
      ) === -1;
    return {
      ...baseOption,
      disabled: optionUnAvailable && !baseOption.selected,
    };
  }

  private getFilterOptions(
    facet: string,
    facets: SearchFilterV2Dls[]
  ): SearchFilterV2OptionDls[] {
    return facets.find((_facet) => _facet.facet === facet)?.options || [];
  }

  private mapBaseFiltersOptionsDisabled(
    baseFilters: SearchFilterV2Dls[],
    appliedFilters: SearchFilterV2Dls[]
  ): SearchFilterV2Dls[] {
    return baseFilters.map((_filter: SearchFilterV2Dls) => {
      const { facet, options, multiType, type } = _filter;
      // FILTERS WITH TYPE SINGLE OR MULTI#OR LOGIC SHOULD NOT HAVE DISABLED OPTIONS
      if (multiType === 'or' || type === 'single') {
        return _filter;
      }
      // BASE FILTERS OPTIONS THAT ARE NOT AVAILABLE WITH APPLIED FILTERS SHOULD BE DISABLED.
      const mappedOptions: SearchFilterV2OptionDls[] = options.map((option) => {
        return this.markFilterOptionDisabled(
          option,
          this.getFilterOptions(facet, appliedFilters)
        );
      });

      return {
        ..._filter,
        options: mappedOptions,
      } as SearchFilterV2Dls;
    });
  }

  private mapFiltersToDLSFilters(
    filters: SearchFilterV2[],
    selectedFilters: SelectedFilter = {}
  ): SearchFilterV2Dls[] {
    // Build filters with subcategories
    const newFilterList = filters.flatMap((_filter) =>
      _filter.subcategories ? this.buildSubcategoryFilters(_filter) : [_filter]
    );

    return newFilterList.map(
      (_filter) =>
        new SearchFilterV2Dls(_filter, this.translateService, selectedFilters)
    );
  }

  private buildSubcategoryFilters(subcategoryFilter: SearchFilterV2) {
    let { groupings } = this.awardSupplemental;
    groupings = orderBy(groupings, ['name']);

    return groupings
      .filter((group) => group.facet === subcategoryFilter.facet)
      .map((group) => ({
        ...subcategoryFilter,
        name: group.name,
        description: group.description,
        options: this.buildOptionsFromSupplemental(
          group,
          subcategoryFilter.options
        ),
      }));
  }

  private buildOptionsFromSupplemental(
    group: AwardGroup,
    options: SearchFilterV2OptionDls[]
  ): SearchFilterV2OptionDls[] {
    const mainOptions = this.matchCodesToOptions(group.codes, options);
    const subcategoryItems = flatMap(group.subcategories, (subcategory) => {
      const subcategoryHeader = {
        name: subcategory.name,
        value: '',
        type: 'subcategory',
      };
      const subcategoryOptions = this.matchCodesToOptions(
        subcategory.codes,
        options
      );
      return [subcategoryHeader, ...subcategoryOptions];
    });

    return subcategoryItems.length > 1
      ? [...mainOptions, ...subcategoryItems]
      : mainOptions;
  }

  private matchCodesToOptions(codes: string[], options: any) {
    const { attributes } = this.awardSupplemental;

    return codes
      .map((code) => {
        const option = find(options, ['value', code]);
        return option && attributes[code]
          ? { ...option, name: attributes[code].name }
          : option;
      })
      .filter(Boolean);
  }

  private getAwardSupplemental(): Subscription {
    return this.settingsService
      .getSetting('award_supplemental')
      .pipe(take(1))
      .subscribe((setting) => (this.awardSupplemental = setting));
  }

  private convertSortOptionsToFacetOptions(
    sortOptions: SearchSortOption[]
  ): SearchFilterV2OptionDls[] {
    return sortOptions.map(
      (option) =>
        ({
          ...option,
          value: option.query,
          name: option.translation,
        } as SearchFilterV2OptionDls)
    );
  }

  private buildSortFilter(
    sortOptions: SearchSortOption[],
    defaultFilters: SelectedFilter
  ): SearchFilterV2 {
    return new SearchFilterV2({
      name: 'search_sort_by',
      type: 'single',
      group: 'main',
      facet: 'sort',
      default: [defaultFilters['sort']],
      options: this.convertSortOptionsToFacetOptions(sortOptions),
    });
  }

  private buildRadiusFilter(
    locationGeoFacet: {},
    radiusConfig: SearchFilterV2,
    defaultFilters: SelectedFilter,
    searchParamType: SearchParamType
  ): SearchFilterV2 {
    const radiusOptions = this.setRadiusOptions(
      locationGeoFacet['location_geo'],
      radiusConfig
    );
    let defaultRadius = radiusConfig.default[searchParamType];
    // defaultFilters is updated with the expanded radius in effect
    const isExpandedRadius = defaultRadius !== defaultFilters['radius'];
    const isDefaultRadiusAvailable = radiusOptions.find(
      (radius) => radius.value === defaultRadius
    );
    // to account for default filter option not being available;
    defaultRadius = isDefaultRadiusAvailable
      ? defaultRadius
      : isExpandedRadius
      ? defaultFilters['radius']
      : radiusOptions[0]?.value;
    return new SearchFilterV2({
      ...radiusConfig,
      options: radiusOptions,
      group: 'main',
      type: 'single',
      name: 'search_filter_refine_distance',
      default: [defaultRadius],
    });
  }

  private mapFacetGeo(facetGeo: any[]): FacetGeo[] {
    const mapped = facetGeo.map((facet) => new FacetGeo(facet));
    return sortBy(mapped, ['radius']);
  }

  private setRadiusOptions(
    LocationGeo: FacetGeo[],
    radiusConfig
  ): SearchFilterV2OptionDls[] {
    const { options } = radiusConfig;
    const facetGeo = this.mapFacetGeo(LocationGeo);
    const updatedOptions = options.filter(
      (option) =>
        !!find(facetGeo, (facet) => `${facet.radius}` === option.value)
    );
    return updatedOptions;
  }

  private filterTogglesAndOptions({
    type,
    facet,
    options,
  }: SearchFilterV2): SearchFilterV2OptionDls[] {
    if (type === 'toggle') {
      if (
        facet === 'contract_accepting_new_patients' &&
        options.some((option) => this.validAnpValues.includes(option.value))
      ) {
        return [{ name: 'true', value: 'true' } as SearchFilterV2OptionDls];
      }
      if (facet === 'virtual_only') {
        const isValidVirtualOnlyOption = options.some((option) => {
          const name = option.name as any;
          const value = option.value as any;
          return name === false && value === false;
        });
        return isValidVirtualOnlyOption
          ? [{ name: 'true', value: 'true' } as SearchFilterV2OptionDls]
          : [];
      }
      const validOption = options.find((option: SearchFilterV2OptionDls) => {
        return (
          option.value === 'true' ||
          option.value === 'Y' ||
          (typeof option.value === 'boolean' && option.value)
        );
      });
      return validOption ? [validOption] : [];
    }
    return options;
  }

  // Merge filters that contain the same facet name
  private mergeLikeFilters(listData: any) {
    // Group filters by facet
    const groupedFilters = groupBy(listData, 'facet');

    return flatMap(groupedFilters, (filters, facet) => {
      if (filters.length === 1) return filters; // No merging needed for single filters

      const baseFilter = filters[0];
      const mergedOptions = flatMap(filters, 'options');
      const cleanOptionsList = reject(mergedOptions, { type: 'subcategory' });
      const selectedCount = sumBy(cleanOptionsList, (option) =>
        option.selected ? 1 : 0
      );

      const mergedFilter = {
        ...baseFilter,
        options: cleanOptionsList,
        selectedCount: selectedCount,
      };

      return [mergedFilter];
    });
  }

  private buildSerpLiteFilters(
    facets: SearchFilterV2Dls[]
  ): SearchFilterV2Dls[] {
    const sortFilter = facets.pop();
    const serpLiteFilter = new SearchFilterV2({
      name: 'serp_lite_custom_filter_name',
      type: 'single',
      group: 'main',
      facet: 'serp lite custom filter',
      options: this.mapSerpLiteOptions(facets),
    });

    return [...this.mapFiltersToDLSFilters([serpLiteFilter]), sortFilter];
  }

  private mapSerpLiteOptions(
    facets: SearchFilterV2[]
  ): SearchFilterV2OptionDls[] {
    const fieldSpecialtyIds = facets.find(
      (facet) => facet.facet === 'field_specialty_ids'
    );
    const expertiseCodes = facets.find(
      (facet) => facet.facet === 'expertise_codes'
    );
    const fieldFacets = fieldSpecialtyIds?.options.length
      ? this.mapFacetType(
          sortBy(fieldSpecialtyIds.options, 'name'),
          'field_specialty_ids'
        )
      : [];
    const expertiseFacets = expertiseCodes?.options.length
      ? this.mapFacetType(
          sortBy(expertiseCodes.options, 'name'),
          'expertise_codes'
        )
      : [];
    return [...fieldFacets, ...expertiseFacets];
  }

  private mapFacetType(
    options: SearchFilterV2OptionDls[],
    type: string
  ): SearchFilterV2OptionDls[] {
    return options.map((facet: SearchFilterV2OptionDls) => {
      return {
        ...facet,
        facet_type: type,
      };
    });
  }
}
