import { filter, map, startWith } from 'rxjs/operators';
import { Component, OnDestroy, ViewChild, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Observable, Subscription, of } from 'rxjs';
import { GlobalHeaderService } from './global-header.service';
import { GlobalHeaderSettings } from './global-header-settings.class';
import { GlobalHeaderAutosuggestComponent } from './autosuggest/global-header-autosuggest.component';
import { SubscriptionManager } from '@zelis/platform-ui-components';
import { SearchTrigger } from '@classes/search-trigger.class';
import { LocationAutosuggest } from '@classes/location-autosuggest.class';
import { TermAutosuggest } from '@classes/term-autosuggest.class';
import { WindowService } from '@services/window.service';
import { RouteUtilities } from '@utilities/route.utilities';
import { StringParseUtilities } from '@utilities/string-parse.utilities';
import { CcssService } from '@services/ccss/ccss.service';
import { MatDialog } from '@angular/material/dialog';
import { Breakpoints } from '@classes/breakpoints.class';
import { SearchView } from '../+search/classes/search-view.class';
import { SettingsService } from '@services/settings.service';
import { Logo } from '@interfaces/logo.model';
import { GpsErrorOverlayComponent } from '../gps-error-overlay/gps-error-overlay.component';
import { LocationGpsStatus } from '@interfaces/location-gps-status.interface';
import { AuthService } from '@services/auth.service';
import { AuthStatus } from '@interfaces/auth-status.model';
import { FeaturesService } from '@services/features/features.service';
import { NetworkSelectionDropdownService } from '@services/network-selection-dropdown/network-selection-dropdown.service';
import { Store, select } from '@ngrx/store';
import { AuthStoreSelectors } from '@store/auth';
import globalHeaderHiddenRoutes from '@utilities/global-header-hidden-routes.utilities';
import { AppParamsService } from '@services/app.params.service';
import { AppParams } from '@interfaces/app.interface.appParams';
import { ChatService } from '@services/pat-chat/chat.service';
import { ProductAnalyticsService } from '@services/product-analytics/product-analytics.service';
import {
  selectActiveMemberActivitiesCount,
  selectActiveMemberActivitiesEnabled,
} from '@store/member-activities/member-activities.selectors';
import { CriticalParamsService } from '@services/critical-params/critical-params.service';

@Component({
  selector: 'app-global-header',
  templateUrl: './global-header.component.html',
  styleUrls: ['./global-header.component.scss'],
})
export class GlobalHeaderComponent implements OnInit, OnDestroy {
  @ViewChild('autosuggest') autosuggest: GlobalHeaderAutosuggestComponent;

  public language = 'en';
  public subscriptions = new SubscriptionManager();
  public showAccountMenu = false;
  public routeUtilities = new RouteUtilities();
  public accountLabel: Observable<string>;
  public showAlphaPrefixTout: boolean = false;
  public languageDisabled: boolean = true;
  public logoutDisabled: boolean = false;
  public headerLogo: Observable<Logo> = this.globalHeaderSettings.logo;
  public ssEnabled: Observable<boolean>;
  public auth: AuthStatus;
  public meaningfulAccessLanguage: boolean = false;
  public networkSelectionDropdownEnabled: Observable<boolean>;
  public openUux: boolean = false;
  public hiddenRoutes: string[] = [];
  public billingPage: boolean = false;
  public showPrintPageLink: boolean = false;
  public loginBtnClass: Observable<string> = this.getLoginBtnClass();
  public suppressMembers: boolean = false;
  public nbaEnabled: Observable<boolean>;
  public memberActivityCount: Observable<number>;
  private stringParseUtilities = new StringParseUtilities();

  constructor(
    public router: Router,
    public globalHeaderService: GlobalHeaderService,
    public globalHeaderSettings: GlobalHeaderSettings,
    public searchTrigger: SearchTrigger,
    public locationAutosuggest: LocationAutosuggest,
    public termAutosuggest: TermAutosuggest,
    public windowService: WindowService,
    public ccss: CcssService,
    public breakpoints: Breakpoints,
    public searchView: SearchView,
    public productAnalyticsService: ProductAnalyticsService,
    private settingsService: SettingsService,
    private dialog: MatDialog,
    private authService: AuthService,
    private featuresService: FeaturesService,
    private networkSelectionDropdownService: NetworkSelectionDropdownService,
    private store: Store<any>,
    private appParams: AppParamsService,
    private chatService: ChatService,
    private criticalParamsService: CriticalParamsService
  ) {}

  ngOnInit() {
    this.updateBillingPage();
    this.subscribeToNavigationEnd();
    this.subscriptions.add(this.subscribeToAutosuggestTrigger());
    this.subscriptions.add(
      this.getSuppressLanguageConfig().subscribe((result) => {
        this.languageDisabled = result ? result : false;
      })
    );
    this.subscriptions.add(
      this.authService.getSuppressLogoutConfig().subscribe((result) => {
        this.logoutDisabled = result;
      })
    );
    this.subscriptions.add(this.getGpsStatus());
    this.setAuth();
    this.setFeatures();
    this.accountLabel = this.globalHeaderService.getAccountLabel();
    this.ssEnabled = this.globalHeaderService.isSsEnabled();
    this.networkSelectionDropdownEnabled =
      this.networkSelectionDropdownService.getNetworkSelectionDropdownConfig();
    this.hideRoutes();
    this.setShowPrintPageLink();
    this.nbaEnabled = this.store.select(selectActiveMemberActivitiesEnabled);
    this.memberActivityCount = this.store.select(
      selectActiveMemberActivitiesCount
    );
  }

  ngOnDestroy() {
    this.subscriptions.destroy();
    this.locationAutosuggest.destroy();
    this.termAutosuggest.subscriptions.destroy();
  }

  public onSSButtonClicked(): void {
    this.router.navigate(['sspromo'], { queryParamsHandling: 'preserve' });
  }

  public goToProfile(): void {
    this.router.navigate(['account'], { queryParamsHandling: 'preserve' });
  }

  public isAutosuggestDisabled(): boolean {
    if (
      this.termAutosuggest.selectedTerm &&
      this.termAutosuggest.selectedTerm.length > 1
    ) {
      return false;
    }
    return true;
  }

  public openAutosuggest() {
    setTimeout(() => {
      if (!this.breakpoints.isMobile) {
        this.autosuggest.autosuggestInput.openPanel();
      } else {
        this.autosuggest.mobileOverlayOpen = true;
      }
      if (this.autosuggest.globalAutocomplete?.options.length > 0) {
        this.autosuggest.globalAutocomplete.options.first.focus();
      }
    });
  }

  public hideRoutes(): void {
    if (
      this.breakpoints.isMobile &&
      !this.globalHeaderService.profileMobileShowToggle
    ) {
      this.hiddenRoutes = globalHeaderHiddenRoutes.mobile;
    } else {
      this.hiddenRoutes = globalHeaderHiddenRoutes.desktop;
    }
  }

  public mobileProfile(): boolean {
    if (
      (this.breakpoints.isMobile &&
        this.routeUtilities.hideOnRoutes(this.router.url, ['profile'])) ||
      this.routeUtilities.hideOnRoutes(this.router.url, ['sspromo'])
    ) {
      return true;
    } else {
      return false;
    }
  }

  public logout(): void {
    if (this.chatService.isConnected.getValue()) {
      this.chatService.endChat();
      // adding setTimeout to wait for the chat session ends before logging out
      setTimeout(() => this.authService.logout(), 1000);
    } else {
      this.authService.logout();
    }
  }

  public onBillingSearch(): void {
    this.router.navigate(['billing-code-search'], {
      queryParamsHandling: 'preserve',
    });
  }

  public onNetworkSelect(networkChange: boolean) {
    this.handleWizardNetworkChange(networkChange);
  }

  private getLoginBtnClass(): Observable<string> {
    return this.ccss.loginButtonClass.pipe(
      map((loginBtnClass) => loginBtnClass + ' no-external-link-indicator')
    );
  }

  private subscribeToAutosuggestTrigger(): Subscription {
    return this.searchTrigger.autosuggest
      .pipe(filter((action: string) => !!action))
      .subscribe((action: string) => {
        this.termAutosuggest.selectGroup(action);
        this.openAutosuggest();
      });
  }

  private setShowAlphaPrefixTout(url: string): void {
    this.showAlphaPrefixTout = false;
    if (this.routeUtilities.hideOnRoutes(url, ['search'])) {
      this.showAlphaPrefixTout = true;
    }
  }

  private subscribeToNavigationEnd(): void {
    this.subscriptions.add(
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.setShowAlphaPrefixTout(event.url);
        }
      })
    );
  }

  private getSuppressLanguageConfig(): Observable<boolean> {
    return this.settingsService.getSetting('suppress_language');
  }

  private getGpsStatus(): Subscription {
    return this.locationAutosuggest.gpsStatus
      .pipe(filter((status) => !!status))
      .subscribe((status) => {
        this.openGpsErrorDialog(status);
      });
  }

  private openGpsErrorDialog(status: LocationGpsStatus): void {
    if (!status.place && !status.loading) {
      this.dialog.open(GpsErrorOverlayComponent);
    }
  }

  private setAuth(): void {
    this.subscriptions.add(
      this.store
        .pipe(select(AuthStoreSelectors.getAuthStatus))
        .subscribe((auth: AuthStatus) => (this.auth = auth))
    );
  }

  private setFeatures(): void {
    this.subscriptions.add(
      this.featuresService.getFeatureFlags().subscribe((features) => {
        this.meaningfulAccessLanguage =
          features && features.meaningful_access_language;
        this.suppressMembers = features?.suppress_members;
      })
    );
  }

  private updateBillingPage(): void {
    this.subscriptions.add(
      this.appParams.resolved.subscribe((params: AppParams) => {
        const pageType = this.routeUtilities.getState();
        const referrer = JSON.parse(params?.referrer || 'null');
        this.billingPage =
          pageType === 'rates' ||
          (pageType === 'profile' && !!referrer?.billing_code);
        this.showPrintPageLink =
          pageType === 'profile' && !!referrer?.billing_code;
      })
    );
  }

  private handleWizardNetworkChange(networkChanged: boolean): void {
    const pageType = this.routeUtilities.getState();
    const criticalParams =
      this.criticalParamsService.getParamsFromUrlAndCritical();
    if ((pageType === 'profile' || pageType === 'search') && networkChanged) {
      this.router.navigate(['/'], { queryParams: criticalParams });
    }
  }

  private setShowPrintPageLink(): void {
    this.subscriptions.add(
      this.router.events
        .pipe(
          filter((event) => event instanceof NavigationEnd),
          startWith(this.router)
        )
        .subscribe((event) => {
          const pageType = this.routeUtilities.getState(event['url']);
          if (pageType === 'billing-code-search' || pageType === 'rates') {
            this.showPrintPageLink = true;
            return;
          }
          this.showPrintPageLink = false;
          return;
        })
    );
  }
}
