import { createAction, props } from '@ngrx/store';

export enum MemberActivitiesActionsTypes {
  loadMemberActivities = '[GET] Member activities request',
  loadMemberActivitiesSuccess = '[GET] Member activities success',
  loadMemberActivitiesFailure = '[GET] Member activities failed',
  setMemberActivitiesEnabled = '[SET] Member activities enabled',
}

export const loadMemberActivities = createAction(
  MemberActivitiesActionsTypes.loadMemberActivities
);

export const loadMemberActivitiesSuccess = createAction(
  MemberActivitiesActionsTypes.loadMemberActivitiesSuccess,
  props<{ memberActivities: any }>()
);

export const loadMemberActivitiesFailure = createAction(
  MemberActivitiesActionsTypes.loadMemberActivitiesFailure,
  props<{ error: any }>()
);

export const setMemberActivitiesEnabled = createAction(
  MemberActivitiesActionsTypes.setMemberActivitiesEnabled,
  props<{ enabled: boolean }>()
);
