import { NgModule, ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NetworkResolver } from '@resolvers/network.resolver';
import { FeaturesResolver } from '@resolvers/features.resolver';
import { SearchSettingsResolver } from '@resolvers/search-settings.resolver';
import { AuthGuard } from '@routeguards/auth.guard';
import { MleGuard } from '@routeguards/mle.guard';
import { NetworkGuard } from '@routeguards/network.guard';
import { EpisodesGuard } from '@routeguards/episodes.guard';
import { RxSavingsGuard } from '@routeguards/rx-savings.guard';
import { CcssResolver } from '@resolvers/ccss.resolver';
import { MembersResolver } from '@resolvers/members.resolver';
import { TranslationResolver } from '@resolvers/translation.resolver';
import { SerpLiteRedirectGuard } from '@routeguards/serp-lite-redirect.guard';
import { CareGuidanceGuard } from '@routeguards/care-guidance.guard';
import { RxCouponsGuard } from '@routeguards/rx-coupons.guard';
import { MsaGuard } from '@routeguards/msa.guard';
import { LegacySearchRedirectGuard } from '@routeguards/legacy-search-redirect.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./components/+home/home.module').then((m) => m.HomeModule),
    resolve: {
      network: NetworkResolver,
      translations: TranslationResolver,
    },
    data: {
      title: 'title_home',
    },
  },
  {
    path: 'msaform',
    loadChildren: () =>
      import('./components/+msa/msa.module').then((m) => m.MsaModule),
    resolve: {
      network: NetworkResolver,
      translations: TranslationResolver,
    },
    data: {
      title: 'title_msa',
    },
    canActivate: [MsaGuard],
  },
  {
    path: 'select/member',
    redirectTo: '/msaform',
    pathMatch: 'full',
    data: {
      title: 'title_msa',
    },
  },
  {
    path: 'account',
    loadChildren: () =>
      import('./components/+member/member.module').then((m) => m.MemberModule),
    resolve: {
      translations: TranslationResolver,
    },
    canActivate: [AuthGuard, MleGuard],
    data: {
      title: 'title_account',
    },
  },
  {
    path: 'billing-code-search',
    loadChildren: () =>
      import('./components/+billing-code/billing-code.module').then(
        (m) => m.BillingCodeModule
      ),
    resolve: {
      translations: TranslationResolver,
    },
    canActivate: [AuthGuard, MleGuard],
    data: {
      title: 'Billing Code Search',
    },
  },
  {
    path: 'coupons',
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./components/+coupons-landing/coupons-landing.module').then(
            (m) => m.CouponsLandingModule
          ),
        canActivate: [RxCouponsGuard],
        resolve: {
          network: NetworkResolver,
          translations: TranslationResolver,
        },
        data: {
          title: 'title_coupons',
        },
      },
      {
        path: ':drug',
        loadChildren: () =>
          import('./components/+coupons-serp/coupons-serp.module').then(
            (m) => m.CouponsSerpModule
          ),
        canActivate: [RxCouponsGuard],
        resolve: {
          network: NetworkResolver,
          translations: TranslationResolver,
        },
        data: {
          title: 'title_coupons',
        },
      },
    ],
  },
  {
    path: 'demo',
    loadChildren: () =>
      import('./components/+demo-login/demo-login.module').then(
        (m) => m.DemoLoginModule
      ),
    resolve: {
      network: NetworkResolver,
      translations: TranslationResolver,
    },
    data: {
      title: 'title_login',
    },
  },
  {
    path: 'directory-download',
    loadChildren: () =>
      import('./components/+directory-download/directory-download.module').then(
        (m) => m.DirectoryDownloadModule
      ),
    resolve: {
      translations: TranslationResolver,
    },
    data: {
      title: 'Download Full Directory',
    },
  },
  {
    path: 'marketing/right-to-shop',
    loadChildren: () =>
      import('./components/+marketing/marketing.module').then(
        (m) => m.MarketingModule
      ),
    canActivate: [AuthGuard, MleGuard],
    resolve: {
      network: NetworkResolver,
      ccssResolver: CcssResolver,
      translations: TranslationResolver,
      filters: SearchSettingsResolver,
    },
    data: {
      name: 'right-to-shop',
      title: 'title_smartshopper_promotional_page',
    },
  },
  {
    path: 'marketing/:name',
    loadChildren: () =>
      import('./components/+marketing/marketing.module').then(
        (m) => m.MarketingModule
      ),
    resolve: {
      network: NetworkResolver,
      ccssResolver: CcssResolver,
      translations: TranslationResolver,
      filters: SearchSettingsResolver,
    },
    data: {
      title: 'title_marketing',
    },
  },
  {
    path: 'medication-finder',
    children: [
      {
        path: '',
        loadChildren: () =>
          import(
            './components/+medication-finder/medication-finder.module'
          ).then((m) => m.MedicationFinderModule),
        canActivate: [AuthGuard, RxSavingsGuard],
        resolve: {
          ccss: CcssResolver,
          network: NetworkResolver,
          translations: TranslationResolver,
        },
        data: {
          title: 'title_medication_finder',
        },
      },
      {
        path: ':drug',
        loadChildren: () =>
          import(
            './components/+medication-finder-serp/medication-finder-serp.module'
          ).then((m) => m.MedicationFinderSerpModule),
        canActivate: [AuthGuard, RxSavingsGuard],
        resolve: {
          ccss: CcssResolver,
          network: NetworkResolver,
          translations: TranslationResolver,
        },
        data: {
          title: 'title_medication_finder',
        },
      },
    ],
  },
  {
    path: 'profile/:provider_id/:location_id/:referrer',
    loadChildren: () =>
      import('./components/+profile/profile.module').then(
        (m) => m.ProfileModule
      ),
    resolve: {
      network: NetworkResolver,
      feature: FeaturesResolver,
      members: MembersResolver,
      translations: TranslationResolver,
    },
    data: {
      title: 'title_provider_profile',
    },
  },
  {
    path: 'rates',
    loadChildren: () =>
      import('@components/+serp/serp.module').then((m) => m.SerpModule),
    canActivate: [NetworkGuard, AuthGuard, MleGuard],
    resolve: {
      network: NetworkResolver,
      feature: FeaturesResolver,
      translations: TranslationResolver,
    },
    data: {
      title: 'title_rates_search',
    },
  },
  {
    path: 'saved-providers',
    loadChildren: () =>
      import(
        './components/+rates-saved-providers/rates-saved-providers.module'
      ).then((m) => m.RatesSavedProvidersModule),
    canActivate: [NetworkGuard, AuthGuard, MleGuard],
    resolve: {
      network: NetworkResolver,
      feature: FeaturesResolver,
      translations: TranslationResolver,
    },
    data: {
      title: 'tinc_print_rates_save_for_print',
    },
  },
  {
    path: 'search/rts/:procedure_id/:page',
    loadChildren: () =>
      import('./components/+rts/rts.module').then((m) => m.RtsModule),
    canActivate: [MleGuard, NetworkGuard],
    resolve: {
      ccss: CcssResolver,
      network: NetworkResolver,
      translations: TranslationResolver,
    },
    data: {
      title: 'title_rts_search',
    },
  },
  {
    path: 'specialty_search/:search_specialty_id',
    loadChildren: () =>
      import('./components/+specialty-search/specialty-search.module').then(
        (m) => m.SpecialtySearchModule
      ),
    canActivate: [NetworkGuard],
    resolve: {
      ccss: CcssResolver,
      network: NetworkResolver,
      translations: TranslationResolver,
    },
    data: {
      title: 'title_specialty_search',
    },
  },
  {
    path: 'search',
    children: [
      {
        path: 'search_specialties/:search_specialty_id',
        loadChildren: () =>
          import('./components/+search/search.module').then(
            (m) => m.SearchModule
          ),
        canActivate: [SerpLiteRedirectGuard],
        data: {
          title: 'title_provider_search',
        },
      },
      {
        path: 'name/:name',
        loadChildren: () =>
          import('./components/+search/search.module').then(
            (m) => m.SearchModule
          ),
        data: {
          title: 'title_provider_search',
        },
      },
      {
        path: 'hospital_affiliations/:hospital_affiliation_ids',
        loadChildren: () =>
          import('./components/+search/search.module').then(
            (m) => m.SearchModule
          ),
        data: {
          title: 'title_provider_search',
        },
      },
      {
        path: 'group_affiliations/:group_affiliation_ids',
        loadChildren: () =>
          import('./components/+search/search.module').then(
            (m) => m.SearchModule
          ),
        data: {
          title: 'title_provider_search',
        },
      },
      {
        path: 'procedures/:procedure_id',
        loadChildren: () =>
          import('./components/+search/search.module').then(
            (m) => m.SearchModule
          ),
        canActivate: [MleGuard, NetworkGuard],
        data: {
          title: 'title_provider_procedure_cost',
        },
      },
    ],
    resolve: {
      network: NetworkResolver,
      filters: SearchSettingsResolver,
      members: MembersResolver,
      translations: TranslationResolver,
    },
  },
  {
    path: 'sspromo',
    redirectTo: 'marketing/right-to-shop',
    pathMatch: 'full',
  },
  {
    path: 'support/:page',
    loadChildren: () =>
      import('./components/+pages/pages.module').then((m) => m.PagesModule),
    resolve: {
      translations: TranslationResolver,
    },
    data: {
      title: 'title_support',
    },
  },
  {
    path: 'carepath',
    loadChildren: () =>
      import('./components/+episodes/episodes.module').then(
        (m) => m.EpisodesModule
      ),
    canActivate: [EpisodesGuard, AuthGuard, MleGuard],
    resolve: {
      network: NetworkResolver,
      members: MembersResolver,
      translations: TranslationResolver,
    },
  },
  {
    path: 'compare/:providers/:referrer',
    loadChildren: () =>
      import('./components/+compare/compare.module').then(
        (m) => m.CompareModule
      ),
    data: {
      title: 'title_compare',
    },
    resolve: {
      network: NetworkResolver,
      members: MembersResolver,
      translations: TranslationResolver,
    },
  },
  {
    path: 'surgery-support',
    loadChildren: () =>
      import('./components/+surgery-support/surgery-support.module').then(
        (m) => m.SurgerySupportModule
      ),
    canActivate: [AuthGuard, NetworkGuard, CareGuidanceGuard],
    resolve: {
      network: NetworkResolver,
      translations: TranslationResolver,
    },
    data: {
      title: 'title_surgery_support',
    },
  },
  {
    path: 'expert-medical',
    loadChildren: () =>
      import('./components/+expert-medical/expert-medical.module').then(
        (m) => m.ExpertMedicalModule
      ),
    canActivate: [AuthGuard, NetworkGuard, CareGuidanceGuard],
    resolve: {
      network: NetworkResolver,
      translations: TranslationResolver,
    },
    data: {
      title: 'title_expert_medical',
    },
  },
  {
    path: '404',
    loadChildren: () =>
      import('./components/four-oh-four/four-oh-four.module').then(
        (m) => m.FourOhFourModule
      ),
    resolve: {
      network: NetworkResolver,
      translations: TranslationResolver,
    },
    data: {
      title: 'title_not_found',
    },
  },
  {
    path: '**',
    loadChildren: () =>
      import('./components/four-oh-four/four-oh-four.module').then(
        (m) => m.FourOhFourModule
      ),
    canActivate: [LegacySearchRedirectGuard],
    resolve: {
      network: NetworkResolver,
      translations: TranslationResolver,
    },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [
    FeaturesResolver,
    NetworkResolver,
    SearchSettingsResolver,
    AuthGuard,
    MsaGuard,
    MleGuard,
    NetworkGuard,
    EpisodesGuard,
    CareGuidanceGuard,
    CcssResolver,
    MembersResolver,
    TranslationResolver,
    LegacySearchRedirectGuard,
  ],
})
export class AppRoutingModule {}
export const Routing: ModuleWithProviders<RouterModule> =
  RouterModule.forRoot(routes);
