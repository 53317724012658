import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FeatureFlagSet } from '@interfaces/feature-flag-set.interface';

@Injectable({
  providedIn: 'root',
})
export class FeaturesService {
  constructor(public http: HttpClient) {}

  public getFeatureFlags(): Observable<FeatureFlagSet> {
    return this.http.get('/api/features.json').pipe(
      map((results) => {
        return results['features'] && results['features'][0]
          ? results['features'][0]
          : {};
      })
    );
  }
}
