<div class="members-container">
  <mat-form-field
    data-cy="members-dropdown.members-container"
    class="global-members-dropdown"
    appearance="fill"
    [ngClass]="{
      'full-width': fullWidth,
      'dark-bg': darkTheme
    }"
    [attr.aria-label]="'home_member_select' | translate"
    subscriptSizing="dynamic"
  >
    <mat-label class="for-label">
      {{ 'app_global_for' | translate }}
    </mat-label>
    <mat-select
      class="members-select"
      *ngIf="members && members.length > 1"
      #selectRef
      [(ngModel)]="selected"
      (selectionChange)="handleSelect($event.value)"
      name="members"
    >
      <mat-option
        (click)="emitMemberSelection(member)"
        class="members-select-option"
        *ngFor="let member of members; trackBy: trackByFn; let i = index"
        [attr.data-cy]="'members-dropdown.members-select-option-' + i"
        [value]="member"
      >
        <span *ngIf="i === 0 && !member.full_name">{{
          'app_global_search_me_label' | translate
        }}</span>
        <span *ngIf="member.full_name">{{ member.full_name }}</span>
      </mat-option>
    </mat-select>
    <input
      *ngIf="members?.length === 1"
      matInput
      readonly
      value="{{ members[0].full_name }}"
    />

    <!-- Suppressed Members "For: Me" -->
    <input
      data-cy="members-dropdown.suppressed-members"
      class="members-select members-suppressed"
      *ngIf="members?.length === 1 && members[0].full_name === ''"
      matInput
      readonly
      value="{{ 'app_global_search_me_label' | translate }}"
    />
  </mat-form-field>
</div>
