<div
  class="py-3 px-4"
  [ngClass]="{
    'border-t': divider,
    'bg-white': !transparentBackground,
    'bg-transparent': transparentBackground,
    'sm:px-6': dialogContent,
  }"
>
  <div class="flex justify-between items-center">
    <h4 *ngIf="size === 'large'" class="dls-subhead-2-bold text-black">
      {{ primaryText }}
    </h4>
    <h5 *ngIf="size === 'medium'" class="dls-body-1-semibold text-black">
      {{ primaryText }}
    </h5>
    <h6
      *ngIf="size === 'small'"
      class="dls-body-2-semibold text-palette-m3-system-gray-35"
    >
      {{ primaryText }}
    </h6>
    <h6
      *ngIf="secondaryText"
      class="dls-body-2-semibold text-palette-m3-system-gray-35"
    >
      {{ secondaryText }}
    </h6>
  </div>
  <div
    *ngIf="description"
    class="dls-caption-regular text-palette-m3-system-gray-35 description"
  >
    {{ description }}
  </div>
</div>
