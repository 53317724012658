export interface MemberActivitiesState {
  memberActivities: {
    active: any[];
    done: any[];
  };
  activeCount: number;
  error: any | null;
  enabled: boolean;
}

export const initialState: MemberActivitiesState = {
  memberActivities: {
    active: [],
    done: [],
  },
  activeCount: 0,
  error: null,
  enabled: false,
};
