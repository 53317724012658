<!-- Top row desktop -->
<div class="header-container h-16 px-12 flex" *ngIf="!breakpoints.isMobile">
  <div
    class="w-100"
    fxLayout="row"
    fxLayoutAlign="space-between center"
    fxLayoutGap="40px"
    fxLayoutGap.lt-md="16px"
  >
    <!-- client logo -->
    <div fxFlex="grow" fxLayoutAlign="start center">
      <a
        id="primary-logo"
        appUnfocus
        class="no-halo logo-wrapper display-block"
        *ngIf="(headerLogo | async)?.header?.primary_logo as logo"
        (click)="termAutosuggest.activateHome()"
        (keydown.enter)="termAutosuggest.activateHome()"
        [attr.aria-label]="
          ('app_global_client' | translate) +
          ' - ' +
          ('app_return_home' | translate)
        "
        tabindex="0"
      >
        <img
          class="logo-img flex"
          data-cy="global-header.logo-image.desktop"
          [ngClass]="
            ccss.getClass('logo_img') === 'logo_img' ? 'logo_img' : 'h-10'
          "
          [attr.alt]="'app_global_client' | translate"
          [src]="logo"
        />
      </a>
    </div>

    <!-- skip header links/navigation - appears only when users tab through the global header -->
    <app-skip-links class="w-100"></app-skip-links>

    <!-- smartShopper button -->
    <div
      fxFlex
      class="header-section mr-0 xl:mr-6"
      *ngIf="auth.auth_status && (ssEnabled | async)"
    >
      <zelis-nav-button
        [label]="'global_header_about_ss' | translate"
        [leadingIcon]="'dls-money-piggy-bank-light'"
        data-cy="global-header.ssopromo-button"
        (clicked)="onSSButtonClicked()"
        appUnfocus
      ></zelis-nav-button>
    </div>

    <!-- language selection dropdown -->
    <div fxFlex class="header-section mr-0 xl:mr-6" *ngIf="!languageDisabled">
      <app-global-language-select
        appUnfocus
        data-cy="global-header.language-select"
      >
      </app-global-language-select>
    </div>

    <!-- activities button -->
    <div fxFlex class="header-section mr-0 xl:mr-6" *ngIf="nbaEnabled | async">
      <zelis-nav-button
        [label]="'app_global_activities' | translate"
        [showBadge]="(memberActivityCount | async) > 0"
        [badgeCount]="memberActivityCount | async"
        leadingIcon="dls-clipboard-list-light"
        badgeColor="system-red"
        data-cy="global-header.activities-button"
        appUnfocus
      ></zelis-nav-button>
    </div>

    <!-- login button -->
    <div fxFlex class="header-section" *ngIf="!auth.auth_status && auth.url">
      <zelis-button
        analytics
        [color]="'accent'"
        [variant]="'flat'"
        [forwardedClass]="loginBtnClass | async"
        [href]="auth.url"
        data-cy="global-header.login-button"
        (clickFn)="productAnalyticsService.setAuthenticationPage()"
      >
        {{ auth.text | translate }}
      </zelis-button>
    </div>

    <!-- user profile dropdown -->
    <div fxFlex class="header-section" *ngIf="auth.auth_status">
      <ng-container *ngIf="{ ssEnabled: ssEnabled | async } as data">
        <span class="account-dropdown">
          <!-- member profile button -->
          <zelis-nav-button
            [label]="accountLabel | async | translate"
            [leadingIcon]="'dls-user-circle-light'"
            [trailingIcon]="'dls-caret-down-solid'"
            data-cy="global-header.account-button"
            [matMenuTriggerFor]="accountMenu"
            appUnfocus
          ></zelis-nav-button>

          <!-- dropdown menu -->
          <mat-menu
            #accountMenu="matMenu"
            panelClass="account-menu"
            class="account-menu w-56 global-header-dropdown"
          >
            <button
              *ngIf="!suppressMembers"
              class="button-link account-button-option"
              mat-menu-item
              (click)="goToProfile()"
              data-cy="global-header.profile"
            >
              <span class="text-medium">
                {{ 'global_header_profile' | translate }}
              </span>
            </button>
            <button
              *ngIf="!logoutDisabled"
              class="button-link account-button-option"
              mat-menu-item
              (keydown.enter)="logout()"
              (click)="logout()"
              data-cy="global-header.logout"
            >
              <span class="text-medium">{{
                'app_global_logout' | translate
              }}</span>
            </button>
          </mat-menu>
        </span>
      </ng-container>
    </div>
  </div>
</div>

<!-- Top row mobile & serp map view -->
<div class="header-container mobile" *ngIf="breakpoints.isMobile">
  <platform-page-container [fullscreen]="false" [contentSpacing]="false">
    <div class="flex flex-row justify-between items-center gap-6">
      <div>
        <app-mobile-hamburger-menu
          data-cy="global-header.mobile-hamburger-menu"
        ></app-mobile-hamburger-menu>
      </div>
      <div class="flex-grow">
        <a
          id="primary-logo"
          (click)="termAutosuggest.activateHome()"
          (keydown.enter)="termAutosuggest.activateHome()"
          *ngIf="(headerLogo | async)?.header?.mobile_logo as logo"
          tabindex="0"
        >
          <img
            class="logo-img"
            data-cy="global-header.logo-image.mobile"
            [attr.alt]="'app_return_home' | translate"
            [src]="logo"
          />
        </a>
        <app-skip-links></app-skip-links>
      </div>

      <div
        fxFlex
        *ngIf="
          (mobileProfile() && !globalHeaderService.profileMobileShowToggle) ||
          (mobileProfile() && globalHeaderService.profileMobileShowToggle)
        "
      >
        <img
          class="search-img"
          data-cy="global-header.mobile.search-image"
          src="/assets/images/search.svg"
          [attr.alt]="'app_global_search' | translate"
          (click)="
            globalHeaderService.profileMobileShowToggle =
              !globalHeaderService.profileMobileShowToggle
          "
          *ngIf="
            mobileProfile() && !globalHeaderService.profileMobileShowToggle
          "
        />
        <img
          class="search-img"
          src="/assets/images/CloseSearch.svg"
          [attr.alt]="'app_global_close_search' | translate"
          (click)="
            globalHeaderService.profileMobileShowToggle =
              !globalHeaderService.profileMobileShowToggle
          "
          *ngIf="mobileProfile() && globalHeaderService.profileMobileShowToggle"
        />
      </div>
    </div>
    <div class="flex flex-col text-right pt-2" *ngIf="showPrintPageLink">
      <app-save-for-print fxFlex="column" [showPrintPageLink]="true">
      </app-save-for-print>
    </div>
  </platform-page-container>
</div>

<!-- Bottom Row All -->
<div
  class="autosuggest-container py-4 md:p-0 md:pb-2"
  *ngIf="!routeUtilities.hideOnRoutes(router.url, hiddenRoutes)"
  [hidden]="mobileProfile() && !globalHeaderService.profileMobileShowToggle"
  ngClass.lt-md="mobile"
  [hidden]="
    routeUtilities.hideOnRoutes(router.url, hiddenRoutes) ||
    (mobileProfile() && !globalHeaderService.profileMobileShowToggle)
  "
  data-cy="global-header-autosuggest"
>
  <platform-page-container [fullscreen]="false" [contentSpacing]="false">
    <div
      class="autosuggest-container-box flex flex-row gap-2 md:gap-4"
      [class.high-contrast-theme]="breakpoints.isMobile && billingPage"
    >
      <zelis-button
        *ngIf="billingPage"
        size="large"
        [variant]="breakpoints.isMobile ? 'flat' : 'stroked'"
        [color]="breakpoints.isMobile ? 'primary' : 'system-blue'"
        [darkBackground]="breakpoints.isMobile"
        (click)="onBillingSearch()"
      >
        {{ 'tinc_billing_search_link_text' | translate }}
      </zelis-button>

      <app-save-for-print
        *ngIf="billingPage && !breakpoints.isMobile"
        [showPrintPageLink]="true"
      >
      </app-save-for-print>

      <div *ngIf="billingPage" class="grow">
        <!-- space holder -->
      </div>

      <app-guided-search-trigger
        *ngIf="!billingPage"
        class="guided-search-container global-autosuggest-container"
        [ngClass]="breakpoints.isMobile ? 'h-[64px]' : 'h-[58px]'"
      ></app-guided-search-trigger>

      <app-global-header-autosuggest
        *ngIf="!billingPage"
        class="global-autosuggest-container w-[50%] md:w-full grow"
        role="search"
        #autosuggest
        [selectedGroup]="termAutosuggest.selectedGroup"
        [offsetScrim]="true"
        (clearEvent)="termAutosuggest.clear()"
      >
      </app-global-header-autosuggest>

      <!-- non-wizard/default network selection dropdown -->
      <app-global-network-dropdown
        *ngIf="(networkSelectionDropdownEnabled | async) === false"
        class="hidden md:block"
        [ngClass]="ccss.getClass('header_network')"
        analytics
        appUnfocus
        [showAlphaPrefixTout]="showAlphaPrefixTout"
      >
      </app-global-network-dropdown>

      <!-- network selection dropdown (wizard) -->
      <app-network-selection-dropdown
        *ngIf="networkSelectionDropdownEnabled | async"
        class="w-[180px]"
        [hidden]="breakpoints.isMobile"
        (networkSelectionChange)="onNetworkSelect($event)"
        [(openDropdown)]="openWizard"
      >
      </app-network-selection-dropdown>

      <app-global-location-select
        [ngClass]="ccss.getClass('header_location')"
        class="hidden md:block"
        analytics
        [results]="locationAutosuggest.cities"
        [selectedOption]="locationAutosuggest.selectedLocation"
        (termEntered)="locationAutosuggest.onLocationEntered($event)"
        (termSelected)="locationAutosuggest.onLocationSelect($event)"
        (emitBrowserLocationRequested)="
          locationAutosuggest.requestBrowserLocation()
        "
      >
      </app-global-location-select>
    </div>
  </platform-page-container>
</div>

<!-- mobile profile only -->
<div
  class="action-button-container pad top-2x bottom-2x right-1x left-1x"
  *ngIf="mobileProfile() && globalHeaderService.profileMobileShowToggle"
>
  <app-mobile-action-buttons
    types="network,location"
    (openUux)="openWizard = true"
  >
  </app-mobile-action-buttons>
</div>
